header {
  width: 100%;
  top: 0;
  z-index: 1000;
  background: white;
  position: fixed;
  top: 0;
}

.header {
  box-shadow: 0 0 15px rgba(176, 216, 253, 0.192);
}

.logo {
  width: 250px;
  padding: 10px 0;
}
.logo img {
  width: 100%;
}

/* nav part start ----------------------------- */

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}



nav .main-ul {
  display: flex;
  margin: 0;
}

nav .main-ul li {
  padding: 30px 15px;
  transition: 1s;
}

nav .main-ul li a {
  font-size: 16px;
  font-weight: 300;
  color: gray;
}

nav .main-ul li a:hover {
  color: #0ddf0d;
}

nav .main-ul li i {
  margin-left: 10px;
}

.nav-top-mobile {
  display: none;
}

.drop {
  justify-content: space-between;
  display: flex;
  position: absolute;
  border-bottom: none;
  justify-content: flex-start;
  top: 100%;
  left: 0;
  background: rgb(255, 255, 255);
  overflow: hidden;
  z-index: -1;
  height: 0;
  transition: all 0.5s ease-out;
}

.drop ul {
  min-width: 200px;
  padding: 10px 10;
  border-left: 1px solid rgb(245, 244, 244);
}

.drop ul li {
  text-align: left;
  padding: 5px 0;
}

.drop ul li a {
  font-weight: 300;
  display: block;
  font-size: 16px;
  padding: 5px 10px;
  color: #000;
  transition: 0.5s all ease-in-out;
}

.drop ul li a:hover {
  margin-left: 5px;
  color: #00e171;
}

.hamburger-menu {
  display: none;
  font-size: 20px;
  margin-right: 20px;
}

@media (min-width: 851px) {
  .main-ul li:hover > .drop {
    z-index: 100;
    height: auto;
  }
}

@media (max-width: 850px) {
  nav {
    padding: 20px;
  }
  .logo {
    width: 150px;
  }

  .menu {
    width: 300px;
    position: absolute;
    top: 105px;
    left: -100%;
    transition: 0.5s ease-in-out;
    background: white;
    max-height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
    z-index: 10000;
  }

  .mobile-menu-link {
    left: 0;
  }

  nav .main-ul {
    padding: 10px 30px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: right;
  }

  nav .main-ul li {
    width: 100%;
    display: block;
    text-align: left;
    margin: 10px 0;
    padding: 5px 0;
  }

  nav .main-ul li a {
    font-size: 18px;
  }

  nav .main-ul li i {
    float: right;
    margin-top: 4px;
    padding: 0 20px;
  }

  .hamburger-menu {
    display: inline-block;
  }

  .drop {
    width: 100%;
    padding: 0;
    display: none;
    position: relative;
    top: 0;
    left: 0;
    background: white;
    border: none;
    height: auto;
    z-index: 1000;
  }

  .more-leng {
    display: none;
  }

  .more-leng a {
    font-size: 16px;
  }

  .openDrop {
    display: block;
  }

  .drop ul {
    width: 100%;
    margin-top: 10px;
    padding: 0px;
  }

  .drop ul li {
    width: 100%;
    display: block;
  }

  .drop ul li:hover {
    background: #0270af;
    color: white;
  }

  .drop ul li a {
    font-size: 16px;
    padding: 0;
    margin-left: 5px;
    display: block;
  }
}

@media (max-width: 500px) {
  .menu {
    width: 100%;
  }
}

footer {
  color: #ffffff;
  border-top: 2px solid #6d6d6d;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover;
}
.footer-wrapper{
background: #000000da;
  width: 100%;
height: 100%;
}

.footer-contaner{
  padding-bottom: 70px;


}
.footer-contaner .fbox {
  padding-top: 30px;
  padding-left: 20px;
}
.footer-contaner .fbox ul {
  padding-left: 0;
}

.footer-contaner .footer-logo {
  width: 200px;
}

.footer-contaner .box1 .logo {
  width: 50%;
}

.footer-contaner .fbox h4 {
  margin: 20px 0;
  font-size: 18px;
  color: rgb(255, 255, 255);
  font-weight: 400;
}

.footer-contaner .fbox ul li {
  margin: 10px 0;
}

.footer-contaner .fbox .con li {
  display: flex;
  margin: 20px 0;
  color: rgb(255, 255, 255);
}

.icon-box {
  margin-right: 20px;
}

.icon {
  width: 20px;
}

.footer-contaner .fbox p ,.footer-contaner .fbox li a{
  color: #d9d9d9;
  word-break: break-word;
  font-weight: 300;
}

.footer-contaner .fbox li a:hover {
  color: #06ac60;
}

.solutions {
  padding: 10px 0;
  font-size: 16px;
  font-weight: 500;
}
.copyright {
  background-color: #000000e6;
}
.copyright p {
  font-size: 14px;
  text-align: center;
  color: white;
}

@media (max-width: 550px) {
  .footer-contaner {
    padding: 25px 15px;
  }
  .footer-contaner .fbox {
    padding-top: 30px;
    padding-left: 10px;
  }
}

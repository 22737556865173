/* homeimg start ----------------------------- */

.home-img {
  display: block;
  height: 80vh;
  object-fit: cover;
}
.mobile-banner {
  display: none;
}
.desktop-banner {
  display: block;
}
.text-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  background: linear-gradient(145deg, #00e1709e, #0086d4);
  justify-content: left;
  align-items: center;
  color: white;
}
.home-text-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  color: white;
}

.text-box .text {
  margin-left: 9%;
}
.text-hover,
.btn {
  transition: all 0.5s ease-in-out;
}

.img:hover .text-hover {
  transform: scale(0.95);
  text-align: left;
}

.img:hover .btn {
  transform: scale(0.95);
  margin-left: 20px;
}
.img-hover {
  overflow: hidden;
}
.img-hover img {
  transition: ease-in-out 0.5s;
}
.img-hover:hover img {
  transform: scale(1.1);
  margin-left: 20px;
}

h1 {
  font-weight: 500;
  font-size: 58px;
  line-height: 55px;
  margin-bottom: 20px;
  text-shadow: 2px 2px 9px #0000002e;
}

.text-box p,
.home-text p {
  font-size: 18px;
  word-spacing: 3px;
  font-weight: 400;
  margin: 30px 0;
  text-align: left;
  color: white;
  text-shadow: 2px 2px 9px #00000061;
}

/* homeimg end ----------------------------- */
.vid-wrapper{
  position: relative;
}
.vid-wrapper video{
display: block;
}

.home-about .row {
  position: relative;
}

.home-about .row > * {
  padding-right: calc(var(--bs-gutter-x) * 0);
  padding-left: calc(var(--bs-gutter-x) * 0);
}

.about-text-wrapper {
  position: absolute;
  width: 50%;
  height: 100%;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
}

.about-text {
  background: rgb(255, 255, 255);
  padding: 40px 50px 40px 20px;
}

.about-text .button a,
.home-about-us .button a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.about-text .icon,
.home-about-us .icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #0086d4;
  color: white;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

/* pdf section start----------------------------- */

.question-box{
  height: 100%;
   background: #efefef;
   border-radius: 10px;
}
.flip-box{
   padding: 10px;
}
.flip-box-inner {
  position: relative;
  text-align: center;
}
.flip-box-inner::after{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  content: "";
  top: 0;
  z-index: 1;
  opacity: 0.8;
  background: linear-gradient(0deg, #000000ec 0%, transparent);
  opacity: 0.9;
}
.question-img{
  width: 100%;
overflow: hidden;
}
.question-img img{
  min-height: 500px;
  
}
.question{
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 100%;
  padding: 20px;
  z-index: 2;

}
.flip-box-inner h2{
  color: #ffffff;
  font-size: 25px;
  text-align: left;
}
.flip-box-inner p{
  color: white;
}


 .ans-box {
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 20px;
  text-align: left;
  -webkit-transform: scale(.9);
  -ms-transform: scale(.9);
  transform: scale(.9);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  pointer-events: none;
  background: linear-gradient(135deg, #00e170, #0086d4);
 z-index: 5;
}

.flip-box-inner:hover>.ans-box {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);

 
}


/* form section start----------------------------- */

.form-content {
  padding: 30px 0 110px 0;
  background: linear-gradient(45deg, #00e1709e, #0086d4);
  color: rgb(58, 58, 58);
}
.contact-form-content {
  padding: 40px 0;
}
.contact-form-content .form {
  background: linear-gradient(45deg, #00e1709e, #0086d4);
}
.form-content2 {
  padding: 40px 0;
  background: linear-gradient(45deg, #00e1709e, #0086d4);
  color: rgb(58, 58, 58);
}

.form {
  box-shadow: 0 0 10px rgba(39, 39, 39, 0.651);
  padding: 20px;
  border-radius: 20px;
  max-width: 700px;
  margin: 0 auto;
  background-color: white;
}
.form1 {
  box-shadow: 0 0 10px rgba(39, 39, 39, 0.651);
  padding: 20px;
  border-radius: 20px;
  margin: 0 auto;
  background-color: white;
}

.form-content .form-control {
  color: #313131;
  background-color: #afadad00;
  border: none;
  border-bottom: 2px solid #2c2c2c;
  appearance: none;
  border-radius: 0;
}
.form-select {
  color: #dbdbdb;
  background-color: #ffffff00;
  border: none;
  border-radius: 0;
  font-size: 16px;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  border-bottom: 2px solid #313131;
}

.form-content .form-control:focus,
.form-content .form-select:focus {
  border-bottom-color: #00e1709e;
  box-shadow: none;
}
.form-content .form-control::placeholder {
  color: #cfcfcf;
  font-size: 14px;
}
.form-btn {
  width: 150px;
  margin: 0 auto;
  margin-top: 60px;
}

@media (max-width: 992px) {
  .about-text-wrapper {
    position: relative;
    width: auto;
    height: auto;
    right: 0;
    top: 0;
  }
}
@media (max-width: 850px) {
  .page-content {
    margin-top: 100px;
  }

  .home-img {
    height: 350px;
    object-fit: cover;
  }

  .text-box .text h1 {
    font-size: 28px;
    line-height: 46px;
  }
  .text-box .text p {
    font-size: 15px;
    margin: 10px 0;
  }

  .form-content {
    padding: 20px;
  }
}
@media (max-width: 768px) {
  .mobile-banner {
    display: block;
  }
  .desktop-banner {
    display: none;
  }
  .text-box .text {
    padding: 20px;
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 550px) {
  .textcontaner .text h1,
  .client h2 {
    font-size: 20px;
  }
  h1 {
    font-size: 30px;
  }
  .main-slid {
    padding: 40px 0;
  }

  .text-box .text {
    margin-left: 5%;
  }

  p {
    margin-bottom: 0rem;
  }
}

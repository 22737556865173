@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

*{
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

p{
  text-align: justify;
  font-size: 17px;
  line-height: 1.8rem;
  letter-spacing: .03125rem;
  color: #000000;
  font-weight: 400;
  margin-bottom: 1rem;
}
a{
    text-decoration: none;
}
ul{
  padding-left: 0;
}
.point_list{
  padding-left: 1.25rem;
}
.point_list li{
  list-style: disc;
}
img {
    width: 100%;
  }
  .img-shadow{
    position: relative;
    width: 100%;
    height: 100%;
  }
  .img-shadow::after{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: linear-gradient(206deg, rgba(0, 0, 0, 0) ,rgb(0 136 212 / 78%))
  }

  .btn {
    border: 1px solid white;
    margin-top: 30px;
    color: white;
    padding:7px 25px;
    border-radius: 50px;
    background:linear-gradient(45deg, #00e1709e, #0086d4) ;
  }
  .btn:hover {
    background:linear-gradient(45deg,#0086d4 ,#00e1709e ) ;
    color: white;
  }

  .flex-center-center{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .gradient-bg{
    background:linear-gradient(45deg, #00e1709e, #0086d4) ;
  }
  .rgradient-bg{
    background:linear-gradient(45deg,#0086d4 ,#00e1709e ) ;
  }
 
  .border-bottom{
    border-bottom: 2px solid rgb(78, 78, 78);
  }

  .page-content {
    width: 100%;
    margin-top: 80px;
  }
  .img {
    width: 100%;
    position: relative;
  }
  
  .img-hover {
    overflow: hidden;
  }
  
  .img-hover img {
    transition: .5s ease-in-out;
  }
  
  .img-hover:hover img {
    transform: scale(1.1);
  }
.heading-color {
    color: #007ec5;
}

.main-img {
    width: 100%;
    height: 375px;
    position: relative;
    overflow: hidden
}

.main-img img {
    height: 100%;
    object-fit: cover
}

.main-img .text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(145deg, #00e1709e, #0086d4);
    display: flex;
    align-items: center;
    justify-content: center
}
.bg1{
  background-color: #ffffff ;
}
.bg2{
  background-color: #efefef ;
}

.main-img .text h2 {
    color: #fff;
    font-size: 50px;
    font-weight: 200
}
.rounded{
  border-radius: .75rem !important;
}
.shadow{
  box-shadow: 0 0 10px rgba(117, 117, 117, 0.23)!important;
}

.mt-90{
margin-top: 90px;
}

.mt-80{
  margin-top: 70px;}

.mt-150{
  margin-top: 150px;
  }
  .quality-control-section {
    color: #fff !important;
    margin:0 40px;
    padding: 10px
}

.quality-control-section p{
    font-weight: 200;
    font-size: 15px;
    color: #fff;  
}
.quality-control-section th,
.fw  {
    font-weight: 200;
    font-size: 16px;
    color: #fff;
}

.qualitylist-wrapper {
    display: flex
}

.qualitylist-wrapper ul li {
    list-style: square;
    word-wrap: break-word;
    word-break: break-all;
    margin-left: 20px;
    color: white;
    font-weight: 200;
    font-size: 14px;
}

@media (max-width: 768px) {
  .mt-90 ,.mt-80 ,.mt-150{
    margin-top: 10px;
    }
    
    .main-img .text h2 {
 
      font-size: 38px;
      font-weight: 300
  }
  }